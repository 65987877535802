<template>
  <div class="AppMenuList">
    <div class="AppMenuList__inner container">
      <div class="AppMenuList__cards">
        <app-menu-card
            class="AppMenuList__item"
            v-for="item in menuList"
            :key="item.id"
            :id="item.item_id"
            :title="item['title_' + selectedLang]"
            :title_ru="item['title_ru']"
            :title_ua="item['title_ua']"
            :title_en="item['title_en']"
            :title_pl="item['title_pl']"
            :weight="item.weight"
            :selected_count="item.count_selected"
            :compositions="item.compositions"
            :description="item['description_' + selectedLang]"
            :price="item.price"
            :imageUrl="getImage(item.image)"
            :addAmount="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppSubtitle from '../../../components/AppSubtitle.vue';
import AppTitle from '../../../components/AppTitle.vue';
import {createNamespacedHelpers} from 'vuex';
import AppMenuCard from '../../../components/AppMenuCard.vue';

import {mapActions, mapGetters} from 'vuex'

export default {
  name: '',
  components: {AppTitle, AppSubtitle, AppMenuCard},
  props: {
    menuList: Array,
    title: String,
    titleEmpty: String,
  },
  mounted() {
    // setInterval(function() {}, 1000);
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      menuTypes: 'typesList',
      menuTypesData: 'types',
    }),
    ...mapGetters('lang', ['selectedLang']),
  },
  methods: {
    getImage(images) {
      if (images?.length) {
        return images[0].title
      }
    },
  }
};
</script>

<style lang="scss">
.AppMenuList {
  margin-bottom: 120px;

  @include media-d-m($screen-netbook) {
    padding-top: 30px;
  }

  &:not(:last-of-type) {
    @include media-d-m($screen-netbook) {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 57px;

    @include media-d-m($screen-netbook) {
      display: none;
      margin-bottom: 0;
    }
  }

  &__cards {
    max-width: 100%;
    @include flex(flex-start, center, row, wrap);
    @include media-d-m($screen-desktop-large) {
      margin-right: 0;
    }

    @include media-d-m($screen-tablet-small) {
      @include flex(center, center, column, wrap);
    }
  }
  &__item {
    margin-right: 20px;
    margin-bottom: 70px;

    @include media-d-m($screen-netbook) {
      margin-bottom: 60px;
    }

    @include media-d-m($screen-tablet-small) {
      margin-right: 0;
    }
  }
}
</style>
