<template>
  <div class="AppMenu">
    <app-menu-bar class="AppMenu__menu-bar" />
    <app-menu-list
      v-for="(type, key) in menuTypes"
      v-show="type.isActive"
      :key="key"
      :title="type['title_' + selectedLang]"
      :menu-list="type.items"
    ></app-menu-list>
  </div>
</template>

<script>
import AppMenuBar                  from './AppMenuBar.vue';
import AppMenuList                 from './AppMenuList.vue';
import { Menu }                    from '../models';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: '',
  data() {
    return {
      menu: {},
    };
  },
  components: {
    AppMenuBar,
    AppMenuList,
  },

  computed: {
    ...mapGetters({
      menuTypes: 'types',
      activeMenus: 'getActiveMenus',
      getProducts: 'getProductsByMenuId',
    }),
    ...mapGetters('lang', ['selectedLang'])
  },

  mounted() {
    let test = 10;

    console.log('MOUNTED-TYPES', this.menuTypes)

    Object.keys(this.menuTypes).forEach((value, key) => {
      this.menu[key] = { isActive: false,  key: key}
    })

    const firstDishList = { ...Menu };
    let result = {};
  },
  created() {
    this.initMenu();
  },
  methods: {
    ...mapActions({ initMenu: 'initMenu' })
  },
};
</script>

<style lang="scss">
.AppMenu__menu-bar {
  margin-bottom: 34px;

  @include media-d-m($screen-netbook) {
    margin-bottom: 0;
  }
}
</style>
