<template>
  <div
      class="AppMenuCard"
      :key="id + weight"
      :data-aos="isReverse ? 'fade-left' : 'fade-right'"
  >
    <div class="AppMenuCard__title">
      <app-title class="title--small title--montserrat title--W500">{{
          title
        }}
      </app-title>
      <span class="AppMenuCard__weight">({{ weight }}г)</span>
    </div>
    <div class="AppMenuCard__img">
      <!--          :src="base_url + 'storage/item-image/' + imageUrl"-->
      <img
          :src="base_url + 'storage/public/item-image/' + imageUrl"
          alt=""
          class="AppMenuCard__image"
      />
    </div>
    <div class="AppMenuCard__composition">
      {{ composition }}
    </div>
    <div class="AppMenuCard__buy">
      <div class="AppMenuCard__price">
        <app-title
            class="AppMenuCard__price-title title--small title--montserrat title--W500"
        >{{ price }}
        </app-title
        >
        {{ wallet['text_' + selectedLang] }}
      </div>
      <div class="AppMenuCard__amount">
        <span
            class="amount__minus"
            @click="minusAmount(); addItemInCart(id, amount, title, price, title_ru, title_ua, title_en, title_pl)"
        >
          <icon-minus/>
        </span>

        <input
            type="number"
            name=""
            class="amount"
            v-model="amountGetter"
        />
        <span
            class="amount__plus"
            @click="plusAmount(); addItemInCart(id, amount, title, price, title_ru, title_ua, title_en, title_pl)"
        >
          <icon-plus/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from './AppTitle';
import {mapActions, mapGetters} from 'vuex';
import iconMinus from './svg/iconMinus';
import iconPlus from './svg/iconPlus';
import {BASE_URL} from "@/constants";

export default {
  name: 'AppShoppingCard',
  props: {
    id: Number,
    title: String,
    title_ru: String,
    title_en: String,
    title_pl: String,
    title_ua: String,
    weight: Number,
    imageUrl: String,
    compositions: Array,
    price: Number,
    isReverse: Boolean,
    selected_count: Number
  },
  components: {
    AppTitle,
    iconMinus,
    iconPlus,
  },
  data() {
    return {
      amount: 0,
      base_url: BASE_URL,
      wallet: {
        text_ru: 'грн',
        text_ua: 'грн',
        text_pl: 'hrywien',
        text_en: 'UAH',
      }
    };
  },

  methods: {
    minusAmount: function () {
      if (this.amount > 0) {
        --this.amount;
        this.updateItemAmount({ id: this.id, amount: this.amount })
      }
    },
    plusAmount: function () {
      ++this.amount;
      this.updateItemAmount({ id: this.id, amount: this.amount })
    },

    ...mapActions(['addCartItem', 'updateItemAmount']),

    addItemInCart(id, amount, title, price, title_ru, title_ua, title_en, title_pl) {
      if (amount < 0) {
        this.amount = 0;
      }

      this.addCartItem({
        id: id,
        amount: amount,
        title: title,
        title_ru: title_ru,
        title_ua: title_ua,
        title_en: title_en,
        title_pl: title_pl,
        price: price,
      });
    },
  },
  computed: {
    ...mapGetters(['getCartItems', 'getProductCountById']),

    ...mapGetters('lang', ['selectedLang']),

    amountGetter: {
      get: function () {
        return this.getProductCountById(this.id);
      },

      set: function (newAmount) {
        this.addItemInCart(this.id, newAmount, this.title, this.price, this.title_ru, this.title_ua, this.title_en, this.title_pl)
      }
    },

    composition: function () {
      const compositionLength = this.compositions.length;
      const compositionArray = this.compositions;
      let compositionString = '';

      for (let i = 0; i < compositionLength; i++) {
        if (i === 0) {
          if (compositionLength === 1) {
            compositionString += compositionArray[i]['title_' + this.selectedLang] + '.';
          } else {
            compositionString += compositionArray[i]['title_' + this.selectedLang] + ', ';
          }
        } else if (i === compositionLength - 1) {
          compositionString += compositionArray[i]['title_' + this.selectedLang].toLowerCase() + '.';
        } else {
          compositionString += compositionArray[i]['title_' + this.selectedLang].toLowerCase() + ', ';
        }
      }

      return compositionString;
    },
  },
};
</script>
<style lang="scss">
.AppMenuCard {
  width: calc(33.33% - 20px);

  @include media-d-m($screen-netbook) {
    width: calc(50% - 20px);
  }

  @include media-d-m($screen-tablet-small) {
    width: 100%;
  }

  &__title {
    @include flex(flex-start, flex-end, row, nowrap);
    margin-bottom: 8px;

    & h3 {
      @include media-d-m($screen-netbook) {
        font-size: 26px;
      }
    }
  }

  &__weight {
    @include text($h15, 400, $light-yellow, $f-montserrat);
    margin-left: 15px;
  }

  &__img {
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    height: 200px;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__composition {
    @include text($h16, 300, $light-yellow, $f-montserrat);
    margin-bottom: 15px;
  }

  &__buy {
    @include flex(space-between, center, row, nowrap);
  }

  &__price {
    @include text($h16, 300, $light-yellow, $f-montserrat);
    @include flex(flex-start, flex-end, row, nowrap);
    font-weight: 300;
  }

  &__price-title {
    margin-right: 10px;
  }

  &__amount {
    @include flex(center, center, row, nowrap);
  }
}

.amount {
  @include flex(center, center, row, nowrap);
  @include size(40px, 35px);
  @include text($h20, 300, $light-yellow, $f-montserrat);
  text-align: center;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  @include transition(border-bottom);

  &:focus {
    border-bottom: 1px solid $light-yellow;
  }

  &__minus {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
    padding: 10px 0;

    & svg line {
      @include transition(stroke);
    }

    &:hover svg line {
      stroke: $yellow;
    }
  }

  &__plus {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
    padding: 10px 0;

    & svg line {
      @include transition(stroke);
    }

    &:hover svg line {
      stroke: $yellow;
    }
  }
}
</style>
