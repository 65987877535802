<template>
  <div class="AppMenuBar">
    <div class="AppMenuBar__inner container">
      <div
        class="AppMenuBar__banner"
        :class="{ hidden: this.getIsDropMenuActive }"
      >
        <app-title class="AppMenuBar__title title--yellow" data-aos="fade-down"
          >{{ menuText['text_' + selectedLang] }}</app-title
        >
        <div class="AppMenuBar__subtitle-block" data-aos="fade-left">
          <div class="AppMenuBar__quotes">
            <icon-quotes />
          </div>
          <app-subtitle class="AppMenuBar__subtitle">
            {{getSortedTexts[0]['text_' + this.selectedLang]}}
          </app-subtitle>
        </div>
      </div>
      <div class="AppMenuBar__menu">
        <ul class="AppMenuBar__items">
          <li
            v-for="(type, key) in filterMenuItems(menuTypesData)"
            :key="key"
            class="AppMenuBar__item"
            :class="{ isActive: type.isActive }"
          >
            <app-title
              class="title--small title--montserrat title--W500"
              @click="
                selectType({
                  key: key,
                  data: { isActive: !type.isActive },
                })
              "
              >{{ type['title_' + selectedLang] }}
            </app-title>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AppSubtitle                 from "../../../components/AppSubtitle.vue";
import AppTitle                    from "../../../components/AppTitle.vue";
import IconQuotes                  from "../../../components/svg/IconQuotes.vue";
import {  mapGetters, mapActions } from "vuex";


export default {
  name: "",
  components: { AppTitle, AppSubtitle, IconQuotes },
  data() {
    return {
      menuText: {
        text_ru: 'Меню',
        text_ua: 'Меню',
        text_pl: 'Menu',
        text_en: 'Menu',
      }
      // menuTypes: this.menuTypesData,
      // filter: '0',
    };
  },

  computed: {
    ...mapGetters({
      // menuTypes: 'typesList',
      menuTypesData: "types",
    }),

    ...mapGetters(['getIsDropMenuActive', 'getSortedTexts']),

    ...mapGetters('lang', ['selectedLang']),
  },

  methods: {
    ...mapActions(["selectType"]),

    filterMenuItems: (obj) => {
      let menuItems = { ...obj };
      let falseArr = [];
      let amount = 0;
      let allFalse = false;
      for (const [key, value] of Object.entries(menuItems)) {
        if (!value.isActive) {
          falseArr.push("0");
        }
        ++amount;
      }
      allFalse = falseArr.length === amount;

      console.log("allFalse", allFalse);
      if (allFalse) {
        menuItems[Object.keys(menuItems)[0]].isActive = true;
      }
      return menuItems;
    },
  },
};
</script>

<style lang="scss">
.AppMenuBar {
  background: url("../../../../../assets/images/menu/menu-bar__bg.png") center
    no-repeat;
  background-size: cover;
  margin-top: -135px;
  padding: 187px 0 30px;
  box-shadow: inset 0 85px 85px -15px $body-color;
  position: relative;

  @include media-d-m($screen-desktop-large) {
    padding-bottom: 107px;
  }

  @include media-d-m($screen-netbook) {
    padding-bottom: 197px;
  }

  &::after {
    content: "";
    position: absolute;
    @include position();
    background: $body-color;

    opacity: 0.8;
    z-index: 2;
  }

  &__inner {
    position: relative;
    z-index: 3;

    @include flex(space-between, flex-start, row, nowrap);

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column, nowrap);
    }
  }

  &__banner {
    @include transition(opacity);

    &.hidden {
      opacity: 0;
    }
  }

  &__title {
    margin-bottom: 50px;
  }

  &__subtitle-block {
    @include flex(center, flex-start, row, nowrap);
    margin-left: 120px;

    @include media-d-m($screen-desktop-large) {
      margin-left: 0;
    }

    @include media-d-m($screen-netbook) {
      margin-bottom: 110px;
    }
  }

  &__quotes {
    position: relative;
    margin-right: 10px;
    top: 4px;
  }

  &__subtitle {
    max-width: 530px;
  }

  &__menu {
    @include media-d-m($screen-netbook) {
      margin-bottom: -110px;
    }
  }
  &__items {
    @include media-d-m($screen-netbook) {
      @include flex(flex-start, center, row, nowrap);
      background-color: $body-color;
      position: absolute;
      right: 0;
      left: 0;
      overflow: auto;
      padding: 35px 0 25px 0;
    }
  }

  &__item {
    @include flex(flex-end, center, row, nowrap);
    white-space: nowrap;
    opacity: 0.5;
    cursor: pointer;
    font-weight: 500;

    & h3 {
      @include media-d-m($screen-desktop-large) {
        font-size: 21px;
      }
    }

    &.isActive {
      opacity: 1;
    }

    @include media-d-m($screen-netbook) {
      font-size: $h16;
    }

    &:not(:last-of-type) {
      margin-bottom: 60px;

      @include media-d-m($screen-desktop-large) {
        margin-bottom: 40px;
      }

      @include media-d-m($screen-netbook) {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }

    @include media-d-m($screen-netbook) {
      &:first-of-type {
        padding-left: 30px;
      }
      &:last-of-type {
        padding-right: 30px;
      }
    }
  }
}
</style>
